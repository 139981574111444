// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
// import Services from 'components/units/unitPages/unit/services';
import Components from 'components/CmsComponents/';

import PromptServiceContent from 'components/promptService/promptServiceContent';
import MainServices from 'components/promptService/mainServices';

// Image

// import MapImage from 'images/mapsImages/cdi-bsb.webp';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};
  margin-top: 4.313rem;

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const GridMainServices = styled.div`
  margin-bottom: 4.375rem;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  background: #f4f5f7;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 0 1.25rem 0;
    border-radius: 8px;
  }

  p {
    line-height: 24px !important;
    margin-bottom: 40px !important;
  }

  ul {
    li {
      margin-bottom: 20px;
    }
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottomPart && '1px solid #C6C8CC'};
  ul {
    margin-bottom: 0;
    li {
      :last-child {
        margin-bottom: 2px;
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIUnits ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
    margin-bottom: ${props => props.lastChild && '0'};
    @media only screen and (min-width: 1100px) {
      margin-bottom: ${props => props.lastChild && '3.75rem'};
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: ${props => (props.isFitCardButtonPages ? '1.875rem' : '0')};
    padding-bottom: ${props => props.borderBottom && '1.875rem'};
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  }
`;

const PartDropdown = styled.div`
  grid-column: ${props => props.gridColumn};
  border-top: 1px solid #c6c8cc;
  padding-top: 20px;
  margin-bottom: 10px;
  @media only screen and (min-width: 768px) {
    padding-top: 4.375rem;
    margin-bottom: 3.75rem;
  }
  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIUnits ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 10;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 10px;
    padding-bottom: ${props => props.borderBottom && '1.875rem'};
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  }
  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media only screen and (min-width: 768px) {
      flex-direction: initial;
    }
    :first-child {
      h2 {
        margin-top: 0;
      }
    }
    .dropdown {
      width: 100%;
      flex-direction: column;
      @media only screen and (min-width: 768px) {
        width: 49%;
      }
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];

    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop, markdownCards) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;

  const components = page[0]?.components ?? [];

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      {components.length >= 1 &&
            components?.map(component => {
              return Components(component);
            })}
    </>
  );
};

const CCIItaim = ({ page, location, specialties }) => {
  const [isDesktop, setDesktop] = useState(true);
  const [markdownCards, setMarkdownCards] = useState('');
  let arrCards = '';

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    arrCards = specialties
      .filter(item => item.centroCirurgico === true && item.unity == 'itaim')
      .map(item => item.markdownCards)
      .join('(end)');
    setMarkdownCards(arrCards);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckxet2b2oar980b68nvgeo6ll', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop, markdownCards)}
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckxeu53ogbjif0d71g8fk5t7n" }) {
                featuredMarketing(locales: [en, es, pt_BR]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  activeHighlight
                  highlight
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_SimpleContent {
                    id
                    title
                    name
                    type
                    backgroundColor {
                      hex
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                      datafragment
                      singletexts
                    }
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                customURLBlog
                posts {
                  id
                  slugPost
                  title
                  topic
                  author
                  assetpicker {
                    handle
                    height
                    width
                  }
                  categories {
                    tag
                  }
                }
              }
            }
            specialties {
              name
              unity
              centroCirurgico
              markdownCards
            }
          }
        }
      `}
      render={response => {
        return (
          <CCIItaim
            page={response.gcms.site.pages}
            specialties={response.gcms.specialties}
            location={location}
          />
        );
      }}
    />
  );
};
